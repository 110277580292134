import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./hero.css";

function Hero() {
  const [isFlyerModalOpen, setIsFlyerModalOpen] = useState(false);
  const [isRetreatModalOpen, setIsRetreatModalOpen] = useState(false);
  const flyerUrl = `${process.env.PUBLIC_URL}/Flyers/2025-LOD-Retreats.pdf`;

  useEffect(() => {
    AOS.init();
  }, []);

  const openFlyerModal = () => setIsFlyerModalOpen(true);
  const closeFlyerModal = () => setIsFlyerModalOpen(false);
  const openRetreatModal = () => setIsRetreatModalOpen(true);
  const closeRetreatModal = () => setIsRetreatModalOpen(false);

  return (
    <section className="hero" id="home">
      <div
        className="hero-content container flex items-center justify-center min-h-screen -mt-20"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div
          className="flex flex-col items-center space-y-10 text-center"
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          <h1
            className="text-white text-5xl md:text-6xl font-bold drop-shadow-lg hover:scale-105 blur-border"
            data-aos="zoom-in-up"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            Lake of Dreams
          </h1>
          <h2
            className="text-white text-3xl md:text-4xl font-bold drop-shadow-md hover:scale-105 blur-border transition-all duration-300 hover:text-yellow-300"
            data-aos="flip-up"
            data-aos-duration="800"
            data-aos-delay="600"
          >
            2025 RETREATS AVAILABLE
          </h2>
          <div
            className="flex justify-center space-x-4"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <button
              onClick={openRetreatModal}
              className="px-6 py-3 text-lg font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-500 hover:scale-110 transition duration-300 ease-in-out shadow-md"
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="600"
            >
              Retreats
            </button>
            <button
              onClick={openFlyerModal}
              className="px-6 py-3 text-lg font-semibold text-blue-600 bg-white border-2 rounded-md hover:bg-blue-200 hover:scale-110 transition duration-300 ease-in-out shadow-md"
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="700"
            >
              Flyer
            </button>
          </div>
        </div>
      </div>

      {/* Flyer Modal */}
      {isFlyerModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full h-5/6 flex flex-col">
            <div className="p-4 border-b flex justify-between items-center">
              <h3 className="text-2xl font-bold">Fall Retreats Flyer</h3>
              <button onClick={closeFlyerModal} className="text-2xl font-bold">
                &times;
              </button>
            </div>
            <div className="flex-grow p-4 overflow-auto">
              <iframe
                src={flyerUrl}
                title="Spring Retreats Flyer"
                className="w-full h-full border-none"
              />
            </div>
            <div className="p-4 border-t flex justify-between">
              <a
                href={flyerUrl}
                download
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
              >
                Download Flyer
              </a>
              <button
                onClick={closeFlyerModal}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Retreats Modal */}
      {isRetreatModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full h-5/6 flex flex-col">
            <div className="p-4 border-b flex justify-between items-center">
              <h3 className="text-2xl font-bold">Retreats Sign In</h3>
              <button
                onClick={closeRetreatModal}
                className="text-2xl font-bold"
              >
                &times;
              </button>
            </div>
            <div className="flex-grow p-4">
              <iframe
                src="https://retreats.lakeofdreams.org/signin"
                title="Retreats Sign In"
                className="w-full h-full border-none"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Hero;
