import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./retreats.css";

// Correct import paths
import RetreatImage from "../../assets/Retreat.png";
import RetreatImage1 from "../../assets/Ladies_Retreat.jpeg";
import RetreatImage2 from "../../assets/Retreat.png";

// Define the images array using the imported assets
const images = [RetreatImage, RetreatImage1, RetreatImage2];

function Retreats() {
  const [isFlyerModalOpen, setIsFlyerModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const flyerUrl = `${process.env.PUBLIC_URL}/Flyers/2025-LOD-Retreats.pdf`;

  useEffect(() => {
    AOS.init();
  }, []);

  const openFlyerModal = () => setIsFlyerModalOpen(true);
  const closeFlyerModal = () => setIsFlyerModalOpen(false);
  const openSignupModal = () => setIsSignupModalOpen(true);
  const closeSignupModal = () => setIsSignupModalOpen(false);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const interval = setInterval(nextImage, 5000); // Change image every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <section id="retreats" className="mt-10 bg-base-100 pt-16 pb-10">
      <div
        className="min-h-1 flex flex-col items-center justify-center"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div
          className="bg-white shadow-md rounded-lg p-8 max-w-6xl w-full"
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div
              className="bg-gray-50 p-6 rounded-lg shadow"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <h2
                className="text-2xl text-center font-semibold text-gray-800 mt-10 mb-4"
                data-aos="zoom-in"
                data-aos-duration="800"
                data-aos-delay="600"
              >
                Snowball, Spring & Fall Retreats
              </h2>
              <p
                className="text-gray-700 text-lg mt-5 mb-6 text-center"
                data-aos="zoom-in"
                data-aos-duration="800"
                data-aos-delay="800"
              >
                Beginning Fridays afternoon concluding Sunday morning.
              </p>
              <div
                className="flex items-center justify-center mt-10 space-x-4"
                data-aos="zoom-in"
                data-aos-duration="800"
                data-aos-delay="1000"
              >
                <button
                  onClick={openSignupModal}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded hover:scale-110 transition duration-300 ease-in-out shadow-md"
                >
                  Signup
                </button>
                <button
                  onClick={openFlyerModal}
                  className="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded hover:scale-110 transition duration-300 ease-in-out shadow-md"
                >
                  Flyer
                </button>
              </div>
            </div>
            <div
              className="flex relative"
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <img
                src={images[currentImageIndex]}
                alt={`Retreat ${currentImageIndex + 1}`}
                className="w-full h-full object-cover rounded-lg"
              />
              <button
                onClick={prevImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
              >
                &#10094;
              </button>
              <button
                onClick={nextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
              >
                &#10095;
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Flyer Modal */}
      {isFlyerModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full h-5/6 flex flex-col">
            <div className="p-4 border-b flex justify-between items-center">
              <h3 className="text-2xl font-bold">Retreats Flyer</h3>
              <button onClick={closeFlyerModal} className="text-2xl font-bold">
                &times;
              </button>
            </div>
            <div className="flex-grow p-4 overflow-auto">
              <iframe
                src={flyerUrl}
                title="Retreats Flyer"
                className="w-full h-full border-none"
              />
            </div>
            <div className="p-4 border-t flex justify-between">
              <a
                href={flyerUrl}
                download
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
              >
                Download Flyer
              </a>
              <button
                onClick={closeFlyerModal}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Signup Modal */}
      {isSignupModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full h-5/6 flex flex-col">
            <div className="p-4 border-b flex justify-between items-center">
              <h3 className="text-2xl font-bold">Retreat Signup</h3>
              <button onClick={closeSignupModal} className="text-2xl font-bold">
                &times;
              </button>
            </div>
            <div className="flex-grow p-4">
              <iframe
                src="https://retreats.lakeofdreams.org/signin"
                title="Retreat Signup"
                className="w-full h-full border-none"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Retreats;
